import { createGlobalStyle } from 'styled-components';

export const CustomerGlobalStyle = createGlobalStyle`
  :root {
    background-color: ${({ theme }) => theme.colors.white};
    box-sizing: border-box;
  }
  html,
  body {
    background-color: inherit;
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    line-height: 1.5 !important;
  }

  body,
  #root {
    flex: 1;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  div[role="button"],
  a,
  button,
  label {
    -webkit-tap-highlight-color: transparent;
  }
`;
