import { apiFetch } from '@m/utils/http';
import { createEffect, createEvent } from 'effector';

import { ExistingSubscriptions, SelfServiceMembership, Subscription } from 'types/api';

/**
 * events
 */
export const removeSubscriptionFromCart = createEvent();
export const resetSubscriptionAsyncStatuses = createEvent();

/**
 * effects
 */
export const addSubscriptionToCart = createEffect({
  handler: ({ planId, planUuid }: { planId?: number; planUuid?: string }) => {
    const url = planUuid
      ? `/v2/subscription/subscription-plan/${planUuid}/prepare-checkout`
      : `/customer/subscription-plan/checkout/${planId}`;

    return apiFetch(url, {
      method: 'POST',
    });
  },
});

export const getActiveSubscriptions = createEffect({
  handler: () => apiFetch<ExistingSubscriptions[]>('/customer/subscription'),
});

export const getSiteSubscriptions = createEffect({
  handler: ({ siteId }: { siteId: number }) =>
    apiFetch(`/customer/subscription/plan/site/${siteId}`),
});

export const getAvailableAndActiveSubscriptions = createEffect({
  handler: async ({
    latitude,
    longitude,
    maxDistanceMiles,
  }: {
    latitude: number;
    longitude: number;
    maxDistanceMiles: number;
  }) => {
    const response = await apiFetch('/subscription/search', {
      // @ts-ignore ts-migrate(2322) FIXME: Type '{ latitude: number; longitude: number; maxDi...
      params: {
        latitude,
        longitude,
        maxDistanceMiles,
      },
    });

    if (response && response.success) {
      return response;
    }
    throw new Error('Could not get available and active subscriptions');
  },
});

export const makePaymentForSubscription = createEffect({
  handler: async (
    parameters:
      | { selfServiceSubscriptionId: string; paymentMethodId: number }
      | { subscriptionId: number; paymentMethodId: number },
  ) => {
    let url;
    let body;

    if ('selfServiceSubscriptionId' in parameters) {
      url = `/v2/subscription/purchase`;
      body = {
        selfServiceSubscriptionId: parameters.selfServiceSubscriptionId,
        userPaymentMethodId: parameters.paymentMethodId,
      };
    } else {
      url = `/customer/subscription/${parameters.subscriptionId}/purchase`;
      body = {
        paymentMethodId: parameters.paymentMethodId,
      };
    }

    const response = await apiFetch<Subscription | SelfServiceMembership>(url, {
      method: 'POST',
      body,
    });

    if (response && response.success) {
      return response;
    }
    throw new Error('Failed to purchase for subscription');
  },
});

export const cancelSubscription = createEffect({
  handler: ({
    subscriptionId,
    subscriptionCancellationReasonId,
  }: {
    subscriptionId: number;
    subscriptionCancellationReasonId?: number;
  }) =>
    apiFetch(`/customer/subscription/${subscriptionId}`, {
      method: 'DELETE',
      body: {
        subscriptionCancellationReasonId,
      },
    }),
});
