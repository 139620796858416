import initApp from '@m/utils/initApp';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import CustomerApp from 'apps/customer/CustomerApp';

initApp('customer');

const root = createRoot(document.getElementById('root')!);

if (!navigator.cookieEnabled) {
  document.getElementById('initial-loader')?.remove();
  root.render(
    <div style={{ fontFamily: 'sans-serif', fontSize: 20, fontWeight: 'bold' }}>
      Please turn on cookies to use Metropolis.
    </div>,
  );
} else {
  root.render(
    <Sentry.ErrorBoundary>
      <CustomerApp />
    </Sentry.ErrorBoundary>,
  );
}
